@import 'additions'
@import 'animations'
@import 'date-picker'
@import 'print'
@import 'text'
@import 'variables'

@import '@angular/cdk/overlay-prebuilt.css'
@import 'bootstrap/scss/bootstrap'
@import 'bootstrap-icons/font/bootstrap-icons'
@import 'quill/dist/quill.bubble.css'
@import 'quill'

@import "prismjs/themes/prism.min.css"

@import "@fontsource/source-sans-pro"
@import "@fontsource/source-sans-pro/400.css"
@import "@fontsource/source-sans-pro/600.css"
@import "@fontsource/source-sans-pro/700.css"

@import 'components/accordion-button'
@import 'components/breadcrumb'
@import 'components/buttons'
@import 'components/button-group'
@import 'components/badge'
@import 'components/dropdown'
@import 'components/inputs'
@import 'components/modal'
@import 'components/navigation'
@import 'components/navigation-button'
@import 'components/table'
@import 'components/toast'

html
    background-color: #C9C9C9
    overscroll-behavior-y: none

body
    font-family: "Source Sans Pro", sans-serif
    overflow-y: auto
    overscroll-behavior: none

.tooltip
    font-family: "Source Sans Pro", sans-serif

hr
    opacity: 1
    color: $charcoal-50

.vr
    opacity: 1
    background-color: $charcoal-50

img.icon-sm
    height: $line-height-sm

img.icon-xs
    height: $line-height-xs

img.icon-leading
    padding-right: 0.25rem

.list-group-item
    border-color: $charcoal-50

.list-group-item:last-child
    border-bottom-right-radius: $border-radius-lg
    border-bottom-left-radius: $border-radius-lg

.list-group-item:first-child
    border-top-left-radius: $border-radius-lg
    border-top-right-radius: $border-radius-lg

.overscroll-none
    overscroll-behavior: none

thead th
    border-bottom: 1px solid $charcoal-100

tr
    border-bottom: 1px solid $charcoal-50

.table > thead > tr > th
    padding: 1rem 0

tbody > tr:last-of-type th
    border-bottom: 0

tbody > tr:last-of-type td
    border-bottom: 0

.table td
    padding: 0.8rem 0

@media screen
    .vh-100-screen-safe
        height: calc(100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom))

.vibrant-white
    backdrop-filter: blur(8px) brightness(1.2) saturate(1.2) contrast(0.7)
    background-color: rgba(255, 255, 255, 0.7)

    &.hover
        background-color: rgba(232, 232, 232, 0.75)

.cdk-drop-list-dragging .cdk-drag
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1)

.cdk-drag-preview
    box-sizing: border-box
    border-radius: 4px
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12)
    padding: 7px

.cdk-list-placeholder
    border-bottom: 1px solid $charcoal-50

table.bookings-print-out
    border-collapse: separate

    & td, tr, th
        word-wrap: break-word
        max-width: 170px
        min-width: 20px
        padding: 0.5rem 0.25rem

    & td:last-child, th:last-child
        text-align: right

